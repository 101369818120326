import React from "react";
import "./Card.css";
import img1 from "../../../Image/Group (3).png";
import img2 from "../../../Image/Frame (4).png";
import img3 from "../../../Image/Frame (5).png";

const Cards = ({ ordersCountInRiyadhAndHail = {}, statisticsOrders = {} }) => {
  const {
    riyadhEngineersCount = 0,
    hailEngineersCount = 0,

  } = ordersCountInRiyadhAndHail;

  const {
    completedOrdersCount = 0,
    nonCompletedOrdersCount = 0,
    completedOrderForNew = 0,
    nonCompletedOrderForNew = 0,
    completedOrderForPrivate = 0,
    nonCompletedOrderForPrivate = 0,
    completedOrderForMaintance = 0,
    nonCompletedOrderForMaintance = 0,
    completedOrderForOperationCount = 0,
    nonCompletedOrderForOperationCount = 0,
    supervisorCount = 0,
  } = statisticsOrders;

  const isAdmin = localStorage.getItem("userType") === "admin"; // Check userType from localStorage

  const cardsData = [
    {
      id: 1,
      img: img1,
      number: riyadhEngineersCount,
      title: "مهندسين فرع الرياض",
    },
    {
      id: 2,
      img: img1,
      number: hailEngineersCount,
      title: "مهندسين فرع حائل",
    },
    {
      id: 3,
      img: img2,
      number: completedOrdersCount,
      title: "الطلبات المكتملة",
    },
    {
      id: 4,
      img: img3,
      number: nonCompletedOrdersCount,
      title: "الطلبات المؤرشفة",
    },
    {
      id: 5,
      img: img3,
      number: completedOrderForNew + nonCompletedOrderForNew,
      title: "طلبات المشاريع الجديدة",
      details: [
        { number: completedOrderForNew, title: "المكتملة", img: img3 },
        { number: nonCompletedOrderForNew, title: "غير المكتملة", img: img3 },
      ],
    },
    {
      id: 6,
      img: img3,
      number: completedOrderForPrivate + nonCompletedOrderForPrivate,
      title: "طلبات المشاريع الخاصة",
      details: [
        { number: completedOrderForPrivate, title: "المكتملة", img: img3 },
        {
          number: nonCompletedOrderForPrivate,
          title: "غير المكتملة",
          img: img3,
        },
      ],
    },
    {
      id: 7,
      img: img3,
      number: completedOrderForMaintance + nonCompletedOrderForMaintance,
      title: "طلبات مشاريع الصيانة",
      details: [
        { number: completedOrderForMaintance, title: "المكتملة", img: img3 },
        {
          number: nonCompletedOrderForMaintance,
          title: "غير المكتملة",
          img: img3,
        },
      ],
    },
    {
      id: 8,
      img: img3,
      number:
        completedOrderForOperationCount + nonCompletedOrderForOperationCount,
      title: "طلبات مشاريع العمليات",
      details: [
        {
          number: completedOrderForOperationCount,
          title: "المكتملة",
          img: img3,
        },
        {
          number: nonCompletedOrderForOperationCount,
          title: "غير المكتملة",
          img: img3,
        },
      ],
    },
    // Only include card with id: 9 if isAdmin is true
    ...(isAdmin
      ? [
          {
            id: 9,
            img: img1,
            number: supervisorCount	,
            title: "المشرفين",
          },
        ]
      : []),
  ];

  return (
    <div className="cards" id="BarChart-Cards">
      <div className="cards-container">
        {cardsData.map((card) => (
          <div key={card.id} className="card">
            <div className="flex items-center justify-between">
              <img src={card.img} alt={card.title} />
              <div>
                <div className="card-number">{card.number}</div>
                <div className="card-title">{card.title}</div>
              </div>
            </div>

            {card.details && (
              <div className="flex items-center justify-between">
                {card.details.map((detail, index) => (
                  <div
                    key={index}
                    className="detail px-4 py-2 rounded-md"
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "#f0f9ff" : "#e0f7fa", // Alternating background colors
                    }}
                  >
                    <span className="text-sm font-medium">
                      {detail.title}: {detail.number}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cards;
