import React, { useEffect, useState } from "react";
import axios from "axios";
import { Url } from "../../function/FunctionApi";

const SelectConsultant = ({ value, onChange }) => {
  const [consultants, setConsultants] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch consultants from the API
    const fetchConsultants = async () => {
      try {
        const response = await axios.get(`${Url}Consultants`);
        if (response.status === 200 && response.data.data) {
          setConsultants(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching consultants:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchConsultants();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col  items-start w-1/2 ">
      <label>الاستشاري</label>
      <select
        name="Consultant"
        value={value}
        onChange={(e) => onChange(e)}
        required
        className="bg-gray-50 border   border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 "
      >
        <option value="" disabled>
          اختر الاستشاري
        </option>
        {consultants.map((consultant) => (
          <option key={consultant.id} value={consultant.name}>
            {consultant.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectConsultant;
