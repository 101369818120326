import React, { useEffect, useState } from "react";
import axios from "axios";
import { Url } from "../../function/FunctionApi";

const SelectDistrict = ({ value, onChange }) => {
  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch districts from the API
    const fetchDistricts = async () => {
      try {
        const response = await axios.get(`${Url}Neighborhood`);
        if (response.status === 200 && response.data.data) {
          setDistricts(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching districts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDistricts();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col items-start w-1/2">
      <label>المنطقة</label>
      <select
        name="District"
        value={value}
        onChange={(e) => onChange(e)}
        required
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
      >
        <option value="" disabled>
          اختر المنطقة
        </option>
        {districts.map((district) => (
          <option key={district.id} value={district.name}>
            {district.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectDistrict;
