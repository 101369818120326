import { React, useState, useEffect } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import Card from "../../Component/DashoardContant/Cards/Card";
import ProjectList from "../../Component/DashoardContant/Project/Project";
import BarChart from "../../Component/DashoardContant/BarChart/BarChart";
import "./Dashboard.css";
import { fetchDataWithRetries } from "../../function/FunctionApi";
import ProjectListSkeleton from "../../Component/DashoardContant/Project/Skeleton";
import noDataImage from "../../Image/App Illustrations.jpg";
import { Bar } from "react-chartjs-2";

function DashBoard() {
  const [ordersCountInRiyadhAndHail, setOrdersCountInRiyadhAndHail] = useState(
    []
  ); 

  const chartData = {
    labels: ["الاسبوع الاول", "الاسبوع الثاني", "الاسبوع الثالث", "الاسبوع الرابع"],
    datasets: [
      {
        label: " اجمالي المشاريع",
        data: [19, 10, 8, 12],// Example data for each week
        backgroundColor: "rgba(76, 175, 79, 1)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: "رسم بياني لآخر المشاريع خلال الشهر" },
    },
    maintainAspectRatio: false,
  };

  const [statisticsOrders, setStatisticsOrders] = useState([]);
  const [riyadhProjects, setRiyadhProjects] = useState([]);
  const [hailProjects, setHailProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await fetchDataWithRetries(
          "Account/get-count-engineers-in-riyadh-and-hail",
          setOrdersCountInRiyadhAndHail
        ); 

        await fetchDataWithRetries(
          "OrderForSubscribe/statistics-orders",
          setStatisticsOrders
        );
        await fetchDataWithRetries(
          "Admin/OrdersByBranch?branchName=الرياض",
          setRiyadhProjects
        );
        await fetchDataWithRetries(
          "Admin/OrdersByBranch?branchName=حائل",
          setHailProjects
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  console.log(setOrdersCountInRiyadhAndHail);
  const allRiyadhProjects = [
    ...(riyadhProjects.newProjects || []),
    ...(riyadhProjects.maintencesOrders || []),
    ...(riyadhProjects.privateProjects || []),
    ...(riyadhProjects.operationOrders || []),
  ];

  const allHailProjects = [
    ...(hailProjects.maintencesOrders || []),
    ...(hailProjects.privateProjects || []),
    ...(hailProjects.newProjects || []),
    ...(hailProjects.operationOrders || []),
  ];

  return (
    <div className="apDiv dashBoard">
      <Sidebar />
      <div className="body_container" dir="rtl">
        <div className="Header">
          <Header />
        </div>
        <Card
          ordersCountInRiyadhAndHail={ordersCountInRiyadhAndHail}
          statisticsOrders={statisticsOrders}
        />

        <div className="Projects">
          {loading ? (
            <>
              <ProjectListSkeleton />
              <ProjectListSkeleton />
            </>
          ) : (
            <>
              {allRiyadhProjects.length > 0 ? (
                <ProjectList branch="الرياض" projects={allRiyadhProjects} />
              ) : (
                <div className="project-list" id="Project-List-BarChart">
                  <h3>مشاريع اليوم فرع الرياض</h3>
                  <div className="NotFoundProject" dir="rtl">
                    <img
                      src={noDataImage}
                      alt="No data available"
                      className="no-data-image"
                    />
                    <p>لا يوجد بيانات للعرض</p>
                  </div>
                </div>
              )}
              {allHailProjects.length > 0 ? (
                <ProjectList branch="حائل" projects={allHailProjects} />
              ) : (
                <div className="project-list" id="Project-List-BarChart">
                  <h3>مشاريع اليوم فرع حائل</h3>
                  <div className="NotFoundProject" dir="rtl">
                    <img
                      src={noDataImage}
                      alt="No data available"
                      className="no-data-image"
                    />
                    <p>لا يوجد بيانات للعرض</p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      <div className=" h-[500px]"> 


      <Bar data={chartData} options={chartOptions} />
      </div>

        {/* <BarChart /> */}
      </div>
    </div>
  );
}

export default DashBoard;
