const Filter = ({ name, value, onChange, options = [] }) => {
  // Define placeholders and input types for all fields, including the new ones
  const inputTypes = {
    requestNumber: { type: "number", placeholder: "رقم الطلب" },
    contractor: { type: "text", placeholder: "مقدم الطلب" },
    projectType: { type: "text", placeholder: "نوع المشروع" },
    startDate: { type: "date", placeholder: "اختر تاريخ البدء" },
    endDate: { type: "date", placeholder: "اختر تاريخ الانتهاء" },
    consultant: { type: "text", placeholder: "الاستشاري" },
    stationNumber: { type: "text", placeholder: "رقم المحطة" },
    district: { type: "text", placeholder: "المنطقة" },
  };

  const getInputProps = (name) => {
    return inputTypes[name] || { type: "text", placeholder: "أدخل قيمة" };
  };

  const { type, placeholder } = getInputProps(name);

  return (
    <div className="filter">
      {name === "startDate" || name === "endDate" ? (
        <div className="filterDate">
          <label>
            <span>{name === "startDate" ? "تاريخ البدء:" : "تاريخ الانتهاء:"}</span>
            <input
              type={type}
              name={name}
              value={value}
              onChange={onChange}
              placeholder={placeholder}
            />
          </label>
        </div>
      ) : name === "requestNumber" || name === "contractor" || name === "projectType" || name === "consultant" || name === "stationNumber" || name === "district" ? (
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      ) : (
        <select name={name} value={value} onChange={onChange}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default Filter;
