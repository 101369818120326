import React, { useState, useEffect } from "react";
import logo from "../../Image/image-removebg-preview (1) 1.png";
import userImages from "../../Image/Group (3).png";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { setCookie } from "../../Pages/Login/Login";

function NavBar({ userData }) {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    setCookie("user", "", -1);
    navigate("/login");
    setExpanded(false); // Close the navbar when logging out
  };

  // Close the Navbar when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".navbar-container")) {
        setExpanded(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <nav
      className="navbar-container bg-white shadow-lg top-0 fixed w-full z-50"
      dir="rtl"
    >
      <div className="container mx-auto flex items-center justify-between px-4 py-3">
        {/* Logo */}
        <Link
          to="/main-page"
          className="flex items-center"
          onClick={() => setExpanded(false)}
        >
          <img src={logo} alt="Logo" className="h-10" />
        </Link>

        <button
          className="lg:hidden text-gray-700 focus:outline-none"
          onClick={() => setExpanded(!expanded)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={expanded ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
            ></path>
          </svg>
        </button>

        <div
          className={`${
            expanded ? "block" : "hidden"
          } lg:flex lg:items-center lg:space-x-6 lg:static absolute top-16 left-0 w-full lg:w-auto bg-white lg:bg-transparent `}
        >
          <Link
            to="/main-page"
            className="block px-3 py-2 text-gray-700 hover:text-blue-600 lg:mx-2"
            onClick={() => setExpanded(false)}
          >
            الرئيسية
          </Link>
          <NavDropdown
            title="الاقسام"
            // id="collapsible-nav-dropdown"
            className="block px-3 py-2 text-gray-700 lg:mx-2"
          >
            <NavDropdown.Item
              as={Link}
              to="/Sub-page/Projects"
              onClick={() => setExpanded(false)}
            >
              المشاريع
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/operate-maintain"
              onClick={() => setExpanded(false)}
            >
              العمليات والصيانه
            </NavDropdown.Item>
            {/* <NavDropdown.Item
              as={Link}
              to="/Sub-page/maintains"
              onClick={() => setExpanded(false)}
            >
              الصيانه
            </NavDropdown.Item> */}
            <NavDropdown.Item
              as={Link}
              to="/Sub-page/special-projects"
              onClick={() => setExpanded(false)}
            >
              المشاريع الخاصه
            </NavDropdown.Item>
          </NavDropdown>

          <Link
            to="/statics"
            className="block px-3 py-2 text-gray-700 hover:text-blue-600 lg:mx-2"
            onClick={() => setExpanded(false)}
          >
            الاحصائيات
          </Link>
          <Link
            to="/projects"
            className="block px-3 py-2 text-gray-700 hover:text-blue-600 lg:mx-2"
            onClick={() => setExpanded(false)}
          >
            جميع الطلبات
          </Link>

          <Link
            to="/contactus"
            className="block px-3 py-2 text-gray-700 hover:text-blue-600 lg:mx-2"
            onClick={() => setExpanded(false)}
          >
            تواصل معنا
          </Link>

          <Link
            to="/about"
            className="block px-3 py-2 text-gray-700 hover:text-blue-600 lg:mx-2"
            onClick={() => setExpanded(false)}
          >
            من نحن
          </Link>
        </div>

        {/* User Dropdown */}
        <div className="user-info-dropdown flex items-center space-x-2">
          <NavDropdown
            title={
              <span className="flex items-center space-x-2">
                <img
                  className="w-8 h-8 rounded-full"
                  src={userImages}
                  alt="User"
                />
                <span className="text-gray-700">
                  {userData && userData.displayName}
                </span>
              </span>
            }
            id="user-dropdown"
          >
            <NavDropdown.Item onClick={handleLogout}>
              تسجيل الخروج
            </NavDropdown.Item>
          </NavDropdown>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
