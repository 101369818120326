import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import DeletedProjects from "./DeletedProjects";
import PrivateProjects from "./PrivateProjects";
import MaintenanceRequests from "./MaintainceProjects";
import Operations from "./OperationsProjects";
import { Url } from "../../function/FunctionApi";

function DeleteOrders() {
  const [activeTab, setActiveTab] = useState("المشاريع"); // Default tab
  const [projects, setProjects] = useState([]);
  const [privateProjects, setPrivateProjects] = useState([]);
  const [operations, setOperations] = useState([]);
  const [maintenanceRequests, setMaintenanceRequests] = useState([]); // For orderMaintenanceRequests
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  const tabs = ["المشاريع", "المشاريع الخاصة", "الصيانة", "العمليات"];

  useEffect(() => {
    // Fetch data when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Url}/Search/get-deleted-projects`);
        const {
          newProjects,
          privateProjects,
          operationsAndMaintenanceRequests,
          orderMaintenanceRequests,
        } = response.data;

        setProjects(newProjects);
        setPrivateProjects(privateProjects);
        setOperations(operationsAndMaintenanceRequests);
        setMaintenanceRequests(orderMaintenanceRequests);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("حدث خطأ أثناء تحميل البيانات. يرجى المحاولة لاحقًا.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const renderTabContent = () => {
    switch (activeTab) {
      case "المشاريع":
        return projects.length === 0 ? (
          <p>لا توجد مشاريع محذوفة.</p>
        ) : (
          <DeletedProjects projects={projects} />
        );
      case "المشاريع الخاصة":
        return privateProjects.length === 0 ? (
          <p>لا توجد مشاريع خاصة.</p>
        ) : (
          <PrivateProjects privateProjects={privateProjects} />
        );
      case "الصيانة":
        return maintenanceRequests.length === 0 ? (
          <p>لا توجد طلبات صيانة.</p>
        ) : (
          <MaintenanceRequests maintenanceRequests={maintenanceRequests} />
        );
      case "العمليات":
        return operations.length === 0 ? (
          <p>لا توجد عمليات.</p>
        ) : (
          <Operations operations={operations} />
        );
      default:
        return null;
    }
  };

  return (
    <div className="apDiv">
      {" "}
      <Sidebar />
      <div className="flex-1 flex flex-col" dir="rtl">
        <Header />

        <div className="p-6">
          <div className="flex space-x-4 border-b border-gray-300 pb-2 mb-4 rtl:space-x-reverse">
            {tabs.map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`px-4 py-2 text-sm font-medium rounded-t-md ${
                  activeTab === tab
                    ? "bg-green-600 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                }`}
              >
                {tab}
              </button>
            ))}
          </div>

          <div className="bg-white p-6 rounded-md shadow-md">
            {loading ? (
              <div className="flex justify-center items-center">
                <span className="spinner-border animate-spin">
                  جاري التحميل...
                </span>
              </div>
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : (
              renderTabContent()
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteOrders;
