import { Link } from "react-router-dom";
const RequestCard = ({ request, isChecked, onChange }) => {
  const getProjectTypeRoute = (orderType) => {
    switch (orderType) {
      case "العمليات":
        return "operation";
      case "المشاريع":
        return "newproject";
      case "الصيانة":
        return "maintains";
      default:
        return "privateproject";
    }
  };
  const projectTypeRoute = getProjectTypeRoute(request.type);

  return (
    <div className="request-card">
      <div className="checkbox-container">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={onChange}
          className="custom-checkbox"
        />
      </div>
      {request.type === "المشاريع الخاصة" ? (
        <Link to={`/project/${projectTypeRoute}/${request.id}`}>
          <p>الفرع: {request.branchName}</p>
          <p> اسم المشروع : {request.projectPlace}</p>
          <p> اسم العميل: {request.customer}</p>
          <p>نوع المشروع: {request.type}</p>
          <p>حالة الطلب: {request.isArchive ? "مؤرشف" : "غير مؤرشف"}</p>
        </Link>
      ) : (
        <Link to={`/project/${projectTypeRoute}/${request.id}`}>
          <p>الفرع: {request.branchName}</p>
          <p>رقم الطلب: {request.faultNumber || request.orderNumber}</p>
          <p>مقدم الطلب: {request.contractor}</p>
          <p>نوع المشروع: {request.type}</p>
          <p>حالة الطلب: {request.isArchive ? "مؤرشف" : "غير مؤرشف"}</p>
        </Link>
      )}
    </div>
  );
};
 

export default RequestCard;