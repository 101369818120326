import React, { useEffect, useState } from "react";
import axios from "axios";
import projectImage1 from "../../Image/Rectangle 34.png";
import noDataImage from "../../Image/App Illustrations.jpg";
import { Url } from "../../function/FunctionApi";
import { Link } from "react-router-dom";
import moment from "moment-hijri";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import "./Projects.css";
import { officeNameMap } from "../../util/officeConstants";
import { Tabs, Tab, Box } from "@mui/material";
const Projects = ({ userData }) => {
  const [completed, setCompleted] = useState();
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const isRiyadh = localStorage.getItem("IsRiyadh") === "true";

  const [tabIndexRiyadh, setTabIndexRiyadh] = useState(0);
  const [tabIndexOthers, setTabIndexOthers] = useState(0);

  useEffect(() => {
    const filteredProjectsToDisplay = () => {
      let filtered = projects;

      if (completed === true) {
        filtered = filtered.filter((project) => !project.isArchived);
      } else if (completed === false) {
        filtered = filtered.filter((project) => project.isArchived);
      }

      if (isRiyadh) {
        filtered = filtered.filter((project) => project.Office === "Khurais");
      } else {
        filtered = filtered.filter((project) => project.Office === "Hail");
      }

      return filtered;
    };

    setFilteredProjects(filteredProjectsToDisplay());
  }, []);

  useEffect(() => {
    setSearchTerm("");
  }, [completed]);
  useEffect(() => {
    const filterProjects = () => {
      let filtered = projects;

      // Filter based on completed status
      if (completed === true) {
        filtered = filtered.filter((project) => !project.isArchived);
      } else if (completed === false) {
        filtered = filtered.filter((project) => project.isArchived);
      }

      // Filter based on Riyadh tabs
      if (isRiyadh) {
        switch (tabIndexRiyadh) {
          case 0:
            return filtered;
          case 1:
            return filtered.filter((project) => project.office === "Khurais");
          case 2:
            return filtered.filter((project) => project.office === "East");
          case 3:
            return filtered.filter((project) => project.office === "South");
          case 4:
            return filtered.filter((project) => project.office === "Diriyah");
          case 5:
            return filtered.filter((project) => project.office === "North");
          default:
            return filtered;
        }
      } else {
        // Filter based on Other Cities tabs
        switch (tabIndexOthers) {
          case 0: // "الجميع"
            return filtered;
          case 1:
            return filtered.filter((project) => project.office === "Hail");
          case 2:
            return filtered.filter((project) => project.office === "Baqaa");
          case 3:
            return filtered.filter(
              (project) => project.office === "Al Ghazalah"
            );
          case 4:
            return filtered.filter(
              (project) => project.office === "Al Hulayfah"
            );
          case 5:
            return filtered.filter((project) => project.office === "Moqaq");
          case 6:
            return filtered.filter((project) => project.office === "Al Shumli");
          case 7:
            return filtered.filter((project) => project.office === "Al Shanan");
          case 8:
            return filtered.filter((project) => project.office === "Al Qaed");
          default:
            return filtered;
        }
      }
    };

    setFilteredProjects(filterProjects());
  }, [tabIndexRiyadh, tabIndexOthers, completed, projects, isRiyadh]);
  console.log(filteredProjects);
  useEffect(() => {
    console.log(userData?.branchName);
    const fetchProjects = async () => {
      try {
        setLoading(true);
        const apiUrl = "OrdersinHome/all-projects-inHome";
        const params = {
          branchName: userData?.branchName,
        };

        const response = await axios.get(`${Url}${apiUrl}`, { params });
        const projectData = [
          ...(response.data.MaintenanceProjects || []).map((project) => ({
            ...project,
            type: "الصيانه",
          })),
          ...(response.data.NewProjects || []).map((project) => ({
            ...project,
            type: "المشاريع",
          })),
          ...(response.data.OperationProjects || []).map((project) => ({
            ...project,
            type: "العمليات",
          })),
        ];

        setProjects(projectData);
        setFilteredProjects(projectData);
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };

    if (userData && userData.branchName) {
      fetchProjects();
    }
  }, [userData]);

  useEffect(() => {
    const results = filteredProjects.filter((project) => {
      const searchTermLower = searchTerm.toLowerCase();
      const officeName = officeNameMap[project.office]?.toLowerCase() || "";
      const situationText =
        project.situation === "finish"
          ? "تم التنفيذ"
          : project.situation === "notFinished"
          ? "لم يتم التنفيذ"
          : "جاري";

      return (
        project.orderNumber
          ?.toString()
          .toLowerCase()
          .includes(searchTermLower) ||
        project.faultNumber
          ?.toString()
          .toLowerCase()
          .includes(searchTermLower) ||
        project.appUserId?.toLowerCase().includes(searchTermLower) ||
        officeName.includes(searchTermLower) || // Search in the mapped office name
        project.consultant?.toLowerCase().includes(searchTermLower) ||
        project.contractor?.toLowerCase().includes(searchTermLower) ||
        project.district?.toLowerCase().includes(searchTermLower) ||
        project.estimatedValue
          ?.toString()
          .toLowerCase()
          .includes(searchTermLower) ||
        project.extractNumber
          ?.toString()
          .toLowerCase()
          .includes(searchTermLower) ||
        project.faultType?.toLowerCase().includes(searchTermLower) ||
        project.office?.toLowerCase().includes(searchTermLower) ||
        project.orderCode?.toLowerCase().includes(searchTermLower) ||
        project.orderDate?.toLowerCase().includes(searchTermLower) ||
        project.orderType?.toLowerCase().includes(searchTermLower) ||
        project.projectType?.toLowerCase().includes(searchTermLower) ||
        project.resources?.toLowerCase().includes(searchTermLower) ||
        situationText.includes(searchTermLower) || // Search in the translated situation text
        project.userName?.toLowerCase().includes(searchTermLower)
      );
    });

    setFilteredProjects(results);
  }, [searchTerm, projects, officeNameMap]);

  const handleDelete = async (projectId, projectType) => {
    console.log(projectType);
    const getProjectTypeRoute = (orderType) => {
      switch (orderType) {
        case "المشاريع الخاصة":
          return "privateproject";
        case "المشاريع":
          return "newproject";
        case "العمليات":
          return "operation";
        default:
          return "maintains";
      }
    };

    const projectTypeRoute = getProjectTypeRoute(projectType);

    const result = await Swal.fire({
      title: "هل أنت متأكد؟",
      text: "لا يمكنك التراجع عن هذا!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "نعم، احذف!",
      cancelButtonText: "لا، تراجع!",
    });

    if (result.isConfirmed) {
      try {
        const apiUrl = `Search/delete-by-orderidWithType?orderId=${projectId}&type=${projectTypeRoute}`;
        await axios.delete(`${Url}${apiUrl}`);
        setProjects((prevProjects) =>
          prevProjects.filter((project) => project.orderNumber !== projectId)
        );
        setFilteredProjects((prevFiltered) =>
          prevFiltered.filter((project) => project.orderNumber !== projectId)
        );
        setProjects((prevProjects) =>
          prevProjects.filter((project) => project.faultNumber !== projectId)
        );

        setFilteredProjects((prevFiltered) =>
          prevFiltered.filter((project) => project.faultNumber !== projectId)
        );

        Swal.fire({
          position: "center",
          icon: "success",
          title: "تم حذف الطلب بنجاح.",
          showConfirmButton: false,
          timer: 1500,
        });

        window.location.reload();
      } catch (err) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "حدث خطأ أثناء حذف الطلب.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  if (loading) {
    return (
      <div className="latest-projects-container projects-page" dir="rtl">
        <div className="container">
          <div className="search-container">
            <input
              type="text"
              placeholder="ابحث عن مشروع..."
              className="search-input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="cards-container">
            {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
              <div key={index} className="project-card">
                <Skeleton height={200} width={250} />
                <div className="project-info">
                  <h3 className="project-title">
                    <Skeleton width={150} />
                  </h3>
                  <p className="order-number">
                    <Skeleton width={100} />
                  </p>
                  <p className="project-date">
                    <Skeleton width={120} />
                  </p>
                  <Skeleton height={30} width={100} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) return <div>Error: {error}</div>;
  console.log(filteredProjects);
  const completedUi = () => (
    <div className="container">
      <div className="search-container">
        <input
          type="text"
          placeholder="ابحث عن مشروع..."
          className="search-input"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <h4 className="w-[160px] text-[14px]">
          {" "}
          عدد الطلبات ( {filteredProjects.length} )
        </h4>
        <div
          onClick={() => setCompleted(null)}
          class="inline-flex flex-row-reverse gap-3  items-center border border-indigo-300 px-3 py-1.5 rounded-md text-indigo-500 hover:bg-indigo-50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="h-6 w-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M7 16l-4-4m0 0l4-4m-4 4h18"
            ></path>
          </svg>
          <span class="ml-1 font-bold text-lg"> العوده</span>
        </div>
      </div>

      {filteredProjects.length === 0 && searchTerm && (
        <div className="NotFoundProject">
          <img
            src={noDataImage}
            alt="No data available"
            className="no-data-image"
          />
          <p>لا توجد مشاريع مطابقة لبحثك.</p>
        </div>
      )}
      <div className="flex items-center justify-center">
        {isRiyadh ? (
          <Tabs
            value={tabIndexRiyadh}
            onChange={(event, newValue) => setTabIndexRiyadh(newValue)}
            aria-label="Riyadh Tabs"
          >
            <Tab sx={{ color: "#000", fontSize: "18px" }} label="الجميع" />
            <Tab sx={{ color: "#000", fontSize: "18px" }} label="خريص" />
            <Tab sx={{ color: "#000", fontSize: "18px" }} label="الشرق" />
            <Tab sx={{ color: "#000", fontSize: "18px" }} label="الجنوب" />
            <Tab sx={{ color: "#000", fontSize: "18px" }} label="الدرعيه" />
            <Tab sx={{ color: "#000", fontSize: "18px" }} label="الشمال" />
          </Tabs>
        ) : (
          <Tabs
            value={tabIndexOthers}
            onChange={(event, newValue) => setTabIndexOthers(newValue)}
            aria-label="Other Cities Tabs"
          >
            <Tab sx={{ color: "#000", fontSize: "18px" }} label="الجميع" />
            <Tab sx={{ color: "#000", fontSize: "18px" }} label="الحائل" />
            <Tab sx={{ color: "#000", fontSize: "18px" }} label="بقعاء" />
            <Tab sx={{ color: "#000", fontSize: "18px" }} label="الغزاله" />
            <Tab sx={{ color: "#000", fontSize: "18px" }} label="الحليفه" />
            <Tab sx={{ color: "#000", fontSize: "18px" }} label="موقق" />
            <Tab sx={{ color: "#000", fontSize: "18px" }} label="الشملي" />
            <Tab sx={{ color: "#000", fontSize: "18px" }} label="الشنان" />
            <Tab sx={{ color: "#000", fontSize: "18px" }} label="القاعد" />
          </Tabs>
        )}
      </div>

      <div className="w-full  flex justify-center items-center mb-12"></div>

      {filteredProjects.length === 0 && !searchTerm && (
        <div className="NotFoundProject" dir="rtl">
          <img
            src={noDataImage}
            alt="No data available"
            className="no-data-image"
          />
          <p>لا يوجد بيانات للعرض</p>
        </div>
      )}

      <div className="cards-container">
        {filteredProjects.length > 0 &&
          filteredProjects.map((project, index) => (
            <div key={index} className="project-card !w-[300px]">
              <img
                src={
                  project.modelPhotos && project.modelPhotos.length > 0
                    ? project.modelPhotos[0].url.toLowerCase().endsWith(".pdf")
                      ? projectImage1
                      : project.modelPhotos[0].url
                    : projectImage1
                }
                alt={project.faultType || "Project Image"}
                className="project-image"
              />
              <div className="project-info">
                <h3 className="project-title">
                  {project.faultType || "مشروع"}
                </h3>
                <p className="">
                  القسم:
                  {project.type || ""}
                </p>
                <p>
                  رقم امر العمل: {project.faultNumber || project.orderNumber}
                </p>
                {/* <p> رقم الكود : {project.orderCode}</p> */}
                <p>الفرع: {project.branchName || project.orderNumber}</p>
                <p>
                  موقف التنفيذ:{" "}
                  {project.situation === "finish"
                    ? "تم التنفيذ"
                    : project.situation === "notFinished"
                    ? "لم يتم التنفيذ"
                    : "جاري"}
                </p>
                <p>المكتب: {officeNameMap[project.office]}</p>{" "}
                <p>
                  الحاله:{" "}
                  {project.isArchived === true ? "تحت التنفيذ" : "تم التنفيذ"}
                </p>
                <p className="project-date">
                  تاريخ استلام امر العمل:
                  {new Date(project.receiveDateTime).toLocaleDateString()}
                </p>
                <p className="project-date">
                  الحي:
                  {project.district}
                </p>
                <div className="buttonUpdeteDelete">
                  <Link
                    to={
                      project.type === "الصيانه"
                        ? `/maintain-projects/${project.id}`
                        : project.type === "المشاريع الخاصة"
                        ? `/special-projects/${project.id}`
                        : project.type === "المشاريع"
                        ? `/request-projects/${project.id}`
                        : `/operations-maintenance/${project.id}`
                    }
                    className="view-project-button"
                  >
                    تعديل الطلب
                  </Link>
                  <button
                    className="delete-project-button"
                    onClick={() =>
                      handleDelete(
                        project.faultNumber || project.orderNumber,
                        project.type
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );

  return (
    <div className="latest-projects-container projects-page" dir="rtl">
      {completed === true ? (
        completedUi()
      ) : completed === false ? (
        completedUi()
      ) : (
        <div className="w-full flex justify-center items-center h-[45vh] gap-10">
          <div
            onClick={() => setCompleted(true)}
            className="flex flex-col items-center cursor-pointer hover:scale-105 transition-all bg-green-500 text-white p-6 rounded-lg shadow-lg w-[250px] h-[200px]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-16 w-16 mb-4"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M16 7a1 1 0 00-1 1v6a1 1 0 001 1 1 1 0 001-1V8a1 1 0 00-1-1zM5 7a1 1 0 00-1 1v6a1 1 0 001 1 1 1 0 001-1V8a1 1 0 00-1-1z"
                clip-rule="evenodd"
              />
            </svg>
            <h2 className="text-xl font-semibold">تم التنفيذ</h2>
            <p className="text-sm">الطلبات المكتملة</p>
          </div>

          <div
            onClick={() => setCompleted(false)}
            className="flex  flex-col items-center cursor-pointer hover:scale-105 transition-all  bg-red-500 text-white p-6 rounded-lg shadow-lg w-[250px] h-[200px]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-16 w-16 mb-4"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a1 1 0 00-1 1v6a1 1 0 001 1h8a1 1 0 001-1V5a1 1 0 00-1-1H8zM7 3a2 2 0 012-2h6a2 2 0 012 2v7H7V3z"
                clip-rule="evenodd"
              />
            </svg>
            <h2 className="text-xl font-semibold">تحت التنفيذ</h2>
            <p className="text-sm">الطلبات تحت التنفيذ </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Projects;
