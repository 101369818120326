import React, { useEffect, useState } from "react";
import "./Accounts.css";
import { fetchDataWithRetries } from "../../function/FunctionApi";
import profilePlaceholder from "../../Image/team-01.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Url } from "../../function/FunctionApi";
import axios from "axios";

function Accounts() {
  const [accounts, setAccounts] = useState([]);
  const [filter, setFilter] = useState("engineer");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [popupVisible, setPopupVisible] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        await fetchDataWithRetries("Account/accounts", setAccounts);
      } catch (error) {
        setError("Error fetching accounts data. Please try again.");
        console.error("Error fetching accounts data:", error);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  const handleFilterChange = (role) => {
    setFilter(role);
  };

  const togglePopup = (id) => {
    setPopupVisible((prevId) => (prevId === id ? null : id));
  };

  const handleDeleteClick = (account) => {
    setAccountToDelete(account);
    setDeleteModalVisible(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(
        `${Url}Account/remove-account?userName=${accountToDelete.userName}`
      );
      setAccounts((prevAccounts) =>
        prevAccounts.filter(
          (account) => account.userName !== accountToDelete.userName
        )
      );
      setDeleteModalVisible(false);
      setPopupVisible(null);
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false);
    setAccountToDelete(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupVisible &&
        !event.target.closest(".options-icon") &&
        !event.target.closest(".popup-menu")
      ) {
        setPopupVisible(null);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [popupVisible]);

  const filteredAccounts = Array.isArray(accounts)
    ? accounts.filter((account) => {
        if (filter === "supervisor")
          return (
            account.userType === "supervisor" ||
            account.userType === "مشرف" ||
            account.userType === "supervisor"
          );

        if (filter === "engineer")
          return (
            account.userType === "مهندس" ||
            account.userType === "eng" ||
            account.userType === "المهندس"
          );
        if (filter === "admins")
          return (
            account.userType === "admin" ||
            account.userType === "admin" ||
            account.userType === "admin"
          );

        return false;
      })
    : [];

  return (
    <div className="accounts-container">
      <div className="accounts-content">
        <div className="filter-buttons">
          <div>
            {localStorage.getItem("userType") === "admin" && (
              <>
                <button
                  onClick={() => handleFilterChange("admins")}
                  className={filter === "admins" ? "active" : ""}
                >
                  المسؤولين
                </button>
                <button
                  onClick={() => handleFilterChange("supervisor")}
                  className={filter === "supervisor" ? "active" : ""}
                >
                  المشرفين
                </button>
              </>
            )}
            <button
              onClick={() => handleFilterChange("engineer")}
              className={filter === "engineer" ? "active" : ""}
            >
              المهندسين
            </button>
          </div>
          <div className="add-account">
            <Link to="/add-account"> إضافة حساب +</Link>
          </div>
        </div>
        {loading ? (
          <p>جاري تحميل البيانات...</p>
        ) : error ? (
          <p className="error-message">{error}</p>
        ) : (
          <div className="accounts-list">
            {filteredAccounts.length > 0 ? (
              filteredAccounts.map((account) => (
                <div key={account.id} className="account-card">
                  <img
                    src={account.userImage || profilePlaceholder}
                    alt={account.userName}
                    className="account-image"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = profilePlaceholder;
                    }}
                  />
                  <span className="account-name">{account.userName}</span>
                  <span className="account-email">{account.email}</span>
                  <span
                    className={`account-type ${
                      account.userType === "admin" ? "inactive" : "inactive"
                    }`}
                  >
                    {account.userType === "admin"
                      ? "ادمن"
                      : account.userType === "eng"
                      ? "مهندس"
                      : "مشرف"}
                  </span>
                  <div className="options-icon">
                    <FontAwesomeIcon
                      icon={faEllipsis}
                      onClick={() => togglePopup(account.id)}
                    />
                    {popupVisible === account.id && (
                      <div className="popup-menu">
                        <Link
                          className="edit-button"
                          to={`/add-account/${account.id}`}
                        >
                          <FontAwesomeIcon icon={faEdit} /> تعديل
                        </Link>
                        <div
                          className="delete-button"
                          onClick={() => handleDeleteClick(account)}
                        >
                          <FontAwesomeIcon icon={faTrash} /> مسح
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p>لا توجد بيانات متاحة</p>
            )}
          </div>
        )}
      </div>

      {deleteModalVisible && (
        <div className="delete-modal">
          <div className="delete-modal-content">
            <p>
              هل أنت متأكد من حذف هذا{" "}
              {accountToDelete.userType === "admin" ||
              accountToDelete.userType === "مشرف" ||
              accountToDelete.userType === "Admin"
                ? "المشرف"
                : "المهندس"}{" "}
              ?
            </p>

            <div className="modal-buttons">
              <button onClick={handleConfirmDelete} className="confirm-button">
                تأكيد
              </button>
              <button onClick={handleCancelDelete} className="cancel-button">
                إلغاء
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Accounts;
